export default {
  "xs": [
    "0.75rem",
    {
      "lineHeight": "1rem"
    }
  ],
  "sm": [
    "0.875rem",
    {
      "lineHeight": "1.25rem"
    }
  ],
  "base": [
    "1rem",
    {
      "lineHeight": "1.5rem"
    }
  ],
  "lg": [
    "1.125rem",
    {
      "lineHeight": "1.75rem"
    }
  ],
  "xl": [
    "1.25rem",
    {
      "lineHeight": "1.75rem"
    }
  ],
  "2xl": [
    "1.5rem",
    {
      "lineHeight": "2rem"
    }
  ],
  "3xl": [
    "1.875rem",
    {
      "lineHeight": "2.25rem"
    }
  ],
  "4xl": [
    "2.25rem",
    {
      "lineHeight": "2.5rem"
    }
  ],
  "5xl": [
    "3rem",
    {
      "lineHeight": "1"
    }
  ],
  "6xl": [
    "3.75rem",
    {
      "lineHeight": "1"
    }
  ],
  "7xl": [
    "4.5rem",
    {
      "lineHeight": "1"
    }
  ],
  "8xl": [
    "6rem",
    {
      "lineHeight": "1"
    }
  ],
  "9xl": [
    "8rem",
    {
      "lineHeight": "1"
    }
  ],
  "xxs": "0.5rem",
  "h1": "2.5rem",
  "h2": "2rem",
  "h3": "1.5rem",
  "h4": "1.25rem",
  "rxl": "8vw",
  "rlg": "5vw",
  "rmd": "3vw",
  "rsm": "2vw"
}