export default {
  "bottom": "bottom",
  "center": "center",
  "left": "left",
  "left-bottom": "left bottom",
  "left-top": "left top",
  "right": "right",
  "right-bottom": "right bottom",
  "right-top": "right top",
  "top": "top"
}