export default {
  "sm": "0 1px 1px rgb(0 0 0 / 0.05)",
  "DEFAULT": [
    "0 1px 2px rgb(0 0 0 / 0.1)",
    "0 1px 1px rgb(0 0 0 / 0.06)"
  ],
  "md": [
    "0 4px 3px rgb(0 0 0 / 0.07)",
    "0 2px 2px rgb(0 0 0 / 0.06)"
  ],
  "lg": [
    "0 10px 8px rgb(0 0 0 / 0.04)",
    "0 4px 3px rgb(0 0 0 / 0.1)"
  ],
  "xl": [
    "0 20px 13px rgb(0 0 0 / 0.03)",
    "0 8px 5px rgb(0 0 0 / 0.08)"
  ],
  "2xl": "0 25px 25px rgb(0 0 0 / 0.15)",
  "none": "0 0 #0000",
  "dark": "0 2px 4px rgba(0, 0, 0, 0.35)"
}