export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"og:title","name":"og:title","content":"UOB Theatre"},{"hid":"og:site_name","name":"og:site_name","content":"UOB Theatre"},{"hid":"og:description","name":"og:description","content":"From Aristophanes to Ayckbourn, from Puccini to pantomime, Bristol Student Theatre has it all. Find out about our performances, buy tickets, discover our societies and how to get involved, and sign up to our newsletter to stay updated with all the latest shows."},{"hid":"description","name":"description","content":"From Aristophanes to Ayckbourn, from Puccini to pantomime, Bristol Student Theatre has it all. Find out about our performances, buy tickets, discover our societies and how to get involved, and sign up to our newsletter to stay updated with all the latest shows."},{"name":"keywords","content":"bristol,student,theatre,performing,arts,university,winston,bristol su"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@360;600&display=swap"}],"style":[],"script":[],"noscript":[],"title":"UOB Theatre | The Home Of Bristol Student Theatre"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"