export default [
  "/opt/build/repo/components/**/*.{vue,js,ts}",
  "/opt/build/repo/layouts/**/*.vue",
  "/opt/build/repo/pages/**/*.vue",
  "/opt/build/repo/composables/**/*.{js,ts}",
  "/opt/build/repo/plugins/**/*.{js,ts}",
  "/opt/build/repo/App.{js,ts,vue}",
  "/opt/build/repo/app.{js,ts,vue}",
  "/opt/build/repo/Error.{js,ts,vue}",
  "/opt/build/repo/error.{js,ts,vue}"
]