export default {
  "backgroundColor": [
    "checked",
    "odd",
    "even"
  ],
  "flexDirection": [
    "odd"
  ],
  "dropShadow": [
    "hover"
  ]
}