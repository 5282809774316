import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_eTVJQYlCmx from "/opt/build/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_NfcM908xUJ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.mjs";
import payload_client_5Om5dvb8Jc from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import auth_client_4lHQFpoKNF from "/opt/build/repo/plugins/auth.client.ts";
import font_awesome_4Kz3WSwqBj from "/opt/build/repo/plugins/font-awesome.ts";
import google_analytics_client_KruZkwwzId from "/opt/build/repo/plugins/google-analytics.client.ts";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import vue_apollo_options_dsr5vmTSZw from "/opt/build/repo/plugins/vue-apollo-options.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_eTVJQYlCmx,
  chunk_reload_client_NfcM908xUJ,
  payload_client_5Om5dvb8Jc,
  auth_client_4lHQFpoKNF,
  font_awesome_4Kz3WSwqBj,
  google_analytics_client_KruZkwwzId,
  sentry_3AyO8nEfhE,
  vue_apollo_options_dsr5vmTSZw
]