import { default as administrationrIxZaV94DjMeta } from "/opt/build/repo/pages/administration.vue?macro=true";
import { default as finance_45reportss1JL7sVqXBMeta } from "/opt/build/repo/pages/administration/finance-reports.vue?macro=true";
import { default as indexd5hSPD5qQMMeta } from "/opt/build/repo/pages/administration/index.vue?macro=true";
import { default as indexWWXwSx8VntMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue?macro=true";
import { default as indexklFP8AULJ9Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue?macro=true";
import { default as indexiSnQDn5fdfMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue?macro=true";
import { default as indexB11GvVTpe6Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue?macro=true";
import { default as edit2wQQQjEUHcMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue?macro=true";
import { default as indexYqO5G68QVjMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue?macro=true";
import { default as editF1dnKzkM0VMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue?macro=true";
import { default as indexLROpfMIxobMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue?macro=true";
import { default as createXdUuiywZLLMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue?macro=true";
import { default as permissionsekuQMETpLfMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue?macro=true";
import { default as createVnnrEcPrzbMeta } from "/opt/build/repo/pages/administration/productions/create.vue?macro=true";
import { default as indexqewSNHXCfLMeta } from "/opt/build/repo/pages/administration/productions/index.vue?macro=true";
import { default as ticket_45lookupP1qHM106OvMeta } from "/opt/build/repo/pages/administration/tools/ticket-lookup.vue?macro=true";
import { default as _91performanceId_93w7KMX6ESD3Meta } from "/opt/build/repo/pages/box-office/[performanceId].vue?macro=true";
import { default as indexNGGUdoQWmaMeta } from "/opt/build/repo/pages/box-office/[performanceId]/index.vue?macro=true";
import { default as scanctRIp4xkHwMeta } from "/opt/build/repo/pages/box-office/[performanceId]/scan.vue?macro=true";
import { default as sellDoLdCF3VBkMeta } from "/opt/build/repo/pages/box-office/[performanceId]/sell.vue?macro=true";
import { default as indexQ8hyZg7FV3Meta } from "/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue?macro=true";
import { default as payjjOMhyHAQ9Meta } from "/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue?macro=true";
import { default as configKhuRkhJtRyMeta } from "/opt/build/repo/pages/box-office/config.vue?macro=true";
import { default as indexzipTFOO74XMeta } from "/opt/build/repo/pages/box-office/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexF687YgCQ5cMeta } from "/opt/build/repo/pages/login/activate/[token]/index.vue?macro=true";
import { default as indexwKbr8wa5b6Meta } from "/opt/build/repo/pages/login/forgot/[token]/index.vue?macro=true";
import { default as indexieVCIvjX36Meta } from "/opt/build/repo/pages/login/forgot/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as bookWtFKMtGvX1Meta } from "/opt/build/repo/pages/production/[slug]/book.vue?macro=true";
import { default as indexMh67x8SWwOMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue?macro=true";
import { default as overviewNA0H0c425AMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue?macro=true";
import { default as payVhfOwRF5jPMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue?macro=true";
import { default as ticketsHLNE55WwInMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue?macro=true";
import { default as warningsMuMg4fteUFMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue?macro=true";
import { default as indexgLNORB7qEjMeta } from "/opt/build/repo/pages/production/[slug]/book/index.vue?macro=true";
import { default as indexLN6hzgehzMMeta } from "/opt/build/repo/pages/production/[slug]/index.vue?macro=true";
import { default as productions7Z5P4QhOnPMeta } from "/opt/build/repo/pages/productions.vue?macro=true";
import { default as indexM2ZY58XF7dMeta } from "/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as societiesHRT7zSIftHMeta } from "/opt/build/repo/pages/societies.vue?macro=true";
import { default as indexPjpmHDxOaLMeta } from "/opt/build/repo/pages/society/[slug]/index.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as indexuGXVpVL7Z2Meta } from "/opt/build/repo/pages/user/booking/[reference]/index.vue?macro=true";
import { default as tickets2j9PlMCEx7Meta } from "/opt/build/repo/pages/user/booking/[reference]/tickets.vue?macro=true";
import { default as indexgRS95bcrelMeta } from "/opt/build/repo/pages/user/email-verify/[token]/index.vue?macro=true";
import { default as indexuu6yzSj8DAMeta } from "/opt/build/repo/pages/user/index.vue?macro=true";
import { default as indexgMrurowOphMeta } from "/opt/build/repo/pages/venue/[slug]/index.vue?macro=true";
export default [
  {
    path: administrationrIxZaV94DjMeta?.path ?? "/administration",
    children: [
  {
    name: finance_45reportss1JL7sVqXBMeta?.name ?? "administration-finance-reports",
    path: finance_45reportss1JL7sVqXBMeta?.path ?? "finance-reports",
    children: [],
    meta: finance_45reportss1JL7sVqXBMeta || {},
    alias: finance_45reportss1JL7sVqXBMeta?.alias || [],
    redirect: finance_45reportss1JL7sVqXBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/finance-reports.vue").then(m => m.default || m)
  },
  {
    name: indexd5hSPD5qQMMeta?.name ?? "administration",
    path: indexd5hSPD5qQMMeta?.path ?? "",
    children: [],
    meta: indexd5hSPD5qQMMeta || {},
    alias: indexd5hSPD5qQMMeta?.alias || [],
    redirect: indexd5hSPD5qQMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: indexWWXwSx8VntMeta?.name ?? "administration-productions-productionSlug-bookings-bookingReference",
    path: indexWWXwSx8VntMeta?.path ?? "productions/:productionSlug/bookings/:bookingReference",
    children: [],
    meta: indexWWXwSx8VntMeta || {},
    alias: indexWWXwSx8VntMeta?.alias || [],
    redirect: indexWWXwSx8VntMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue").then(m => m.default || m)
  },
  {
    name: indexklFP8AULJ9Meta?.name ?? "administration-productions-productionSlug-bookings-create-performanceId",
    path: indexklFP8AULJ9Meta?.path ?? "productions/:productionSlug/bookings/create/:performanceId",
    children: [],
    meta: indexklFP8AULJ9Meta || {},
    alias: indexklFP8AULJ9Meta?.alias || [],
    redirect: indexklFP8AULJ9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiSnQDn5fdfMeta?.name ?? "administration-productions-productionSlug-bookings-create",
    path: indexiSnQDn5fdfMeta?.path ?? "productions/:productionSlug/bookings/create",
    children: [],
    meta: indexiSnQDn5fdfMeta || {},
    alias: indexiSnQDn5fdfMeta?.alias || [],
    redirect: indexiSnQDn5fdfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexB11GvVTpe6Meta?.name ?? "administration-productions-productionSlug-bookings",
    path: indexB11GvVTpe6Meta?.path ?? "productions/:productionSlug/bookings",
    children: [],
    meta: indexB11GvVTpe6Meta || {},
    alias: indexB11GvVTpe6Meta?.alias || [],
    redirect: indexB11GvVTpe6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: edit2wQQQjEUHcMeta?.name ?? "administration-productions-productionSlug-edit",
    path: edit2wQQQjEUHcMeta?.path ?? "productions/:productionSlug/edit",
    children: [],
    meta: edit2wQQQjEUHcMeta || {},
    alias: edit2wQQQjEUHcMeta?.alias || [],
    redirect: edit2wQQQjEUHcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexYqO5G68QVjMeta?.name ?? "administration-productions-productionSlug",
    path: indexYqO5G68QVjMeta?.path ?? "productions/:productionSlug",
    children: [],
    meta: indexYqO5G68QVjMeta || {},
    alias: indexYqO5G68QVjMeta?.alias || [],
    redirect: indexYqO5G68QVjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: editF1dnKzkM0VMeta?.name ?? "administration-productions-productionSlug-performances-performanceId-edit",
    path: editF1dnKzkM0VMeta?.path ?? "productions/:productionSlug/performances/:performanceId/edit",
    children: [],
    meta: editF1dnKzkM0VMeta || {},
    alias: editF1dnKzkM0VMeta?.alias || [],
    redirect: editF1dnKzkM0VMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexLROpfMIxobMeta?.name ?? "administration-productions-productionSlug-performances-performanceId",
    path: indexLROpfMIxobMeta?.path ?? "productions/:productionSlug/performances/:performanceId",
    children: [],
    meta: indexLROpfMIxobMeta || {},
    alias: indexLROpfMIxobMeta?.alias || [],
    redirect: indexLROpfMIxobMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue").then(m => m.default || m)
  },
  {
    name: createXdUuiywZLLMeta?.name ?? "administration-productions-productionSlug-performances-create",
    path: createXdUuiywZLLMeta?.path ?? "productions/:productionSlug/performances/create",
    children: [],
    meta: createXdUuiywZLLMeta || {},
    alias: createXdUuiywZLLMeta?.alias || [],
    redirect: createXdUuiywZLLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue").then(m => m.default || m)
  },
  {
    name: permissionsekuQMETpLfMeta?.name ?? "administration-productions-productionSlug-permissions",
    path: permissionsekuQMETpLfMeta?.path ?? "productions/:productionSlug/permissions",
    children: [],
    meta: permissionsekuQMETpLfMeta || {},
    alias: permissionsekuQMETpLfMeta?.alias || [],
    redirect: permissionsekuQMETpLfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue").then(m => m.default || m)
  },
  {
    name: createVnnrEcPrzbMeta?.name ?? "administration-productions-create",
    path: createVnnrEcPrzbMeta?.path ?? "productions/create",
    children: [],
    meta: createVnnrEcPrzbMeta || {},
    alias: createVnnrEcPrzbMeta?.alias || [],
    redirect: createVnnrEcPrzbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/create.vue").then(m => m.default || m)
  },
  {
    name: indexqewSNHXCfLMeta?.name ?? "administration-productions",
    path: indexqewSNHXCfLMeta?.path ?? "productions",
    children: [],
    meta: indexqewSNHXCfLMeta || {},
    alias: indexqewSNHXCfLMeta?.alias || [],
    redirect: indexqewSNHXCfLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/productions/index.vue").then(m => m.default || m)
  },
  {
    name: ticket_45lookupP1qHM106OvMeta?.name ?? "administration-tools-ticket-lookup",
    path: ticket_45lookupP1qHM106OvMeta?.path ?? "tools/ticket-lookup",
    children: [],
    meta: ticket_45lookupP1qHM106OvMeta || {},
    alias: ticket_45lookupP1qHM106OvMeta?.alias || [],
    redirect: ticket_45lookupP1qHM106OvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration/tools/ticket-lookup.vue").then(m => m.default || m)
  }
],
    name: administrationrIxZaV94DjMeta?.name ?? undefined,
    meta: administrationrIxZaV94DjMeta || {},
    alias: administrationrIxZaV94DjMeta?.alias || [],
    redirect: administrationrIxZaV94DjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/administration.vue").then(m => m.default || m)
  },
  {
    path: _91performanceId_93w7KMX6ESD3Meta?.path ?? "/box-office/:performanceId",
    children: [
  {
    name: indexNGGUdoQWmaMeta?.name ?? "box-office-performanceId",
    path: indexNGGUdoQWmaMeta?.path ?? "",
    children: [],
    meta: indexNGGUdoQWmaMeta || {},
    alias: indexNGGUdoQWmaMeta?.alias || [],
    redirect: indexNGGUdoQWmaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/index.vue").then(m => m.default || m)
  },
  {
    name: scanctRIp4xkHwMeta?.name ?? "box-office-performanceId-scan",
    path: scanctRIp4xkHwMeta?.path ?? "scan",
    children: [],
    meta: scanctRIp4xkHwMeta || {},
    alias: scanctRIp4xkHwMeta?.alias || [],
    redirect: scanctRIp4xkHwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/scan.vue").then(m => m.default || m)
  },
  {
    path: sellDoLdCF3VBkMeta?.path ?? "sell",
    children: [
  {
    name: indexQ8hyZg7FV3Meta?.name ?? "box-office-performanceId-sell",
    path: indexQ8hyZg7FV3Meta?.path ?? "",
    children: [],
    meta: indexQ8hyZg7FV3Meta || {},
    alias: indexQ8hyZg7FV3Meta?.alias || [],
    redirect: indexQ8hyZg7FV3Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue").then(m => m.default || m)
  },
  {
    name: payjjOMhyHAQ9Meta?.name ?? "box-office-performanceId-sell-pay",
    path: payjjOMhyHAQ9Meta?.path ?? "pay",
    children: [],
    meta: payjjOMhyHAQ9Meta || {},
    alias: payjjOMhyHAQ9Meta?.alias || [],
    redirect: payjjOMhyHAQ9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue").then(m => m.default || m)
  }
],
    name: sellDoLdCF3VBkMeta?.name ?? undefined,
    meta: sellDoLdCF3VBkMeta || {},
    alias: sellDoLdCF3VBkMeta?.alias || [],
    redirect: sellDoLdCF3VBkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell.vue").then(m => m.default || m)
  }
],
    name: _91performanceId_93w7KMX6ESD3Meta?.name ?? undefined,
    meta: _91performanceId_93w7KMX6ESD3Meta || {},
    alias: _91performanceId_93w7KMX6ESD3Meta?.alias || [],
    redirect: _91performanceId_93w7KMX6ESD3Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/[performanceId].vue").then(m => m.default || m)
  },
  {
    name: configKhuRkhJtRyMeta?.name ?? "box-office-config",
    path: configKhuRkhJtRyMeta?.path ?? "/box-office/config",
    children: [],
    meta: configKhuRkhJtRyMeta || {},
    alias: configKhuRkhJtRyMeta?.alias || [],
    redirect: configKhuRkhJtRyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/config.vue").then(m => m.default || m)
  },
  {
    name: indexzipTFOO74XMeta?.name ?? "box-office",
    path: indexzipTFOO74XMeta?.path ?? "/box-office",
    children: [],
    meta: indexzipTFOO74XMeta || {},
    alias: indexzipTFOO74XMeta?.alias || [],
    redirect: indexzipTFOO74XMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/box-office/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    children: [],
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexF687YgCQ5cMeta?.name ?? "login-activate-token",
    path: indexF687YgCQ5cMeta?.path ?? "/login/activate/:token",
    children: [],
    meta: indexF687YgCQ5cMeta || {},
    alias: indexF687YgCQ5cMeta?.alias || [],
    redirect: indexF687YgCQ5cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/activate/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwKbr8wa5b6Meta?.name ?? "login-forgot-token",
    path: indexwKbr8wa5b6Meta?.path ?? "/login/forgot/:token",
    children: [],
    meta: indexwKbr8wa5b6Meta || {},
    alias: indexwKbr8wa5b6Meta?.alias || [],
    redirect: indexwKbr8wa5b6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/forgot/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexieVCIvjX36Meta?.name ?? "login-forgot",
    path: indexieVCIvjX36Meta?.path ?? "/login/forgot",
    children: [],
    meta: indexieVCIvjX36Meta || {},
    alias: indexieVCIvjX36Meta?.alias || [],
    redirect: indexieVCIvjX36Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: index9WruYoq02MMeta?.name ?? "login",
    path: index9WruYoq02MMeta?.path ?? "/login",
    children: [],
    meta: index9WruYoq02MMeta || {},
    alias: index9WruYoq02MMeta?.alias || [],
    redirect: index9WruYoq02MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: privacyjCuzG6sq6pMeta?.name ?? "privacy",
    path: privacyjCuzG6sq6pMeta?.path ?? "/privacy",
    children: [],
    meta: privacyjCuzG6sq6pMeta || {},
    alias: privacyjCuzG6sq6pMeta?.alias || [],
    redirect: privacyjCuzG6sq6pMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    path: bookWtFKMtGvX1Meta?.path ?? "/production/:slug/book",
    children: [
  {
    name: indexMh67x8SWwOMeta?.name ?? "production-slug-book-performanceId",
    path: indexMh67x8SWwOMeta?.path ?? ":performanceId",
    children: [],
    meta: indexMh67x8SWwOMeta || {},
    alias: indexMh67x8SWwOMeta?.alias || [],
    redirect: indexMh67x8SWwOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewNA0H0c425AMeta?.name ?? "production-slug-book-performanceId-overview",
    path: overviewNA0H0c425AMeta?.path ?? ":performanceId/overview",
    children: [],
    meta: overviewNA0H0c425AMeta || {},
    alias: overviewNA0H0c425AMeta?.alias || [],
    redirect: overviewNA0H0c425AMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue").then(m => m.default || m)
  },
  {
    name: payVhfOwRF5jPMeta?.name ?? "production-slug-book-performanceId-pay",
    path: payVhfOwRF5jPMeta?.path ?? ":performanceId/pay",
    children: [],
    meta: payVhfOwRF5jPMeta || {},
    alias: payVhfOwRF5jPMeta?.alias || [],
    redirect: payVhfOwRF5jPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue").then(m => m.default || m)
  },
  {
    name: ticketsHLNE55WwInMeta?.name ?? "production-slug-book-performanceId-tickets",
    path: ticketsHLNE55WwInMeta?.path ?? ":performanceId/tickets",
    children: [],
    meta: ticketsHLNE55WwInMeta || {},
    alias: ticketsHLNE55WwInMeta?.alias || [],
    redirect: ticketsHLNE55WwInMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue").then(m => m.default || m)
  },
  {
    name: warningsMuMg4fteUFMeta?.name ?? "production-slug-book-performanceId-warnings",
    path: warningsMuMg4fteUFMeta?.path ?? ":performanceId/warnings",
    children: [],
    meta: warningsMuMg4fteUFMeta || {},
    alias: warningsMuMg4fteUFMeta?.alias || [],
    redirect: warningsMuMg4fteUFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue").then(m => m.default || m)
  },
  {
    name: indexgLNORB7qEjMeta?.name ?? "production-slug-book",
    path: indexgLNORB7qEjMeta?.path ?? "",
    children: [],
    meta: indexgLNORB7qEjMeta || {},
    alias: indexgLNORB7qEjMeta?.alias || [],
    redirect: indexgLNORB7qEjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book/index.vue").then(m => m.default || m)
  }
],
    name: bookWtFKMtGvX1Meta?.name ?? undefined,
    meta: bookWtFKMtGvX1Meta || {},
    alias: bookWtFKMtGvX1Meta?.alias || [],
    redirect: bookWtFKMtGvX1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/book.vue").then(m => m.default || m)
  },
  {
    name: indexLN6hzgehzMMeta?.name ?? "production-slug",
    path: indexLN6hzgehzMMeta?.path ?? "/production/:slug",
    children: [],
    meta: indexLN6hzgehzMMeta || {},
    alias: indexLN6hzgehzMMeta?.alias || [],
    redirect: indexLN6hzgehzMMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/production/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: productions7Z5P4QhOnPMeta?.name ?? "productions",
    path: productions7Z5P4QhOnPMeta?.path ?? "/productions",
    children: [],
    meta: productions7Z5P4QhOnPMeta || {},
    alias: productions7Z5P4QhOnPMeta?.alias || [],
    redirect: productions7Z5P4QhOnPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/productions.vue").then(m => m.default || m)
  },
  {
    name: indexM2ZY58XF7dMeta?.name ?? "publicity-screen-venueSlugs",
    path: indexM2ZY58XF7dMeta?.path ?? "/publicity-screen/:venueSlugs",
    children: [],
    meta: indexM2ZY58XF7dMeta || {},
    alias: indexM2ZY58XF7dMeta?.alias || [],
    redirect: indexM2ZY58XF7dMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue").then(m => m.default || m)
  },
  {
    name: signupQ6T3vUFXXDMeta?.name ?? "signup",
    path: signupQ6T3vUFXXDMeta?.path ?? "/signup",
    children: [],
    meta: signupQ6T3vUFXXDMeta || {},
    alias: signupQ6T3vUFXXDMeta?.alias || [],
    redirect: signupQ6T3vUFXXDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: societiesHRT7zSIftHMeta?.name ?? "societies",
    path: societiesHRT7zSIftHMeta?.path ?? "/societies",
    children: [],
    meta: societiesHRT7zSIftHMeta || {},
    alias: societiesHRT7zSIftHMeta?.alias || [],
    redirect: societiesHRT7zSIftHMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/societies.vue").then(m => m.default || m)
  },
  {
    name: indexPjpmHDxOaLMeta?.name ?? "society-slug",
    path: indexPjpmHDxOaLMeta?.path ?? "/society/:slug",
    children: [],
    meta: indexPjpmHDxOaLMeta || {},
    alias: indexPjpmHDxOaLMeta?.alias || [],
    redirect: indexPjpmHDxOaLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/society/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms",
    path: terms9FLT7o4CI4Meta?.path ?? "/terms",
    children: [],
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: indexuGXVpVL7Z2Meta?.name ?? "user-booking-reference",
    path: indexuGXVpVL7Z2Meta?.path ?? "/user/booking/:reference",
    children: [],
    meta: indexuGXVpVL7Z2Meta || {},
    alias: indexuGXVpVL7Z2Meta?.alias || [],
    redirect: indexuGXVpVL7Z2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/booking/[reference]/index.vue").then(m => m.default || m)
  },
  {
    name: tickets2j9PlMCEx7Meta?.name ?? "user-booking-reference-tickets",
    path: tickets2j9PlMCEx7Meta?.path ?? "/user/booking/:reference/tickets",
    children: [],
    meta: tickets2j9PlMCEx7Meta || {},
    alias: tickets2j9PlMCEx7Meta?.alias || [],
    redirect: tickets2j9PlMCEx7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/booking/[reference]/tickets.vue").then(m => m.default || m)
  },
  {
    name: indexgRS95bcrelMeta?.name ?? "user-email-verify-token",
    path: indexgRS95bcrelMeta?.path ?? "/user/email-verify/:token",
    children: [],
    meta: indexgRS95bcrelMeta || {},
    alias: indexgRS95bcrelMeta?.alias || [],
    redirect: indexgRS95bcrelMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/email-verify/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuu6yzSj8DAMeta?.name ?? "user",
    path: indexuu6yzSj8DAMeta?.path ?? "/user",
    children: [],
    meta: indexuu6yzSj8DAMeta || {},
    alias: indexuu6yzSj8DAMeta?.alias || [],
    redirect: indexuu6yzSj8DAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: indexgMrurowOphMeta?.name ?? "venue-slug",
    path: indexgMrurowOphMeta?.path ?? "/venue/:slug",
    children: [],
    meta: indexgMrurowOphMeta || {},
    alias: indexgMrurowOphMeta?.alias || [],
    redirect: indexgMrurowOphMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/venue/[slug]/index.vue").then(m => m.default || m)
  }
]