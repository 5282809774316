import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import lockdown_45mode_45global from "/opt/build/repo/middleware/lockdown-mode.global.ts";
export const globalMiddleware = [
  validate,
  lockdown_45mode_45global
]
export const namedMiddleware = {
  admin: () => import("/opt/build/repo/middleware/admin.ts"),
  authed: () => import("/opt/build/repo/middleware/authed.ts"),
  "can-boxoffice": () => import("/opt/build/repo/middleware/can-boxoffice.ts"),
  "not-authed": () => import("/opt/build/repo/middleware/not-authed.ts")
}