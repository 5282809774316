export default {
  "0": "0deg",
  "1": "1deg",
  "2": "2deg",
  "3": "3deg",
  "6": "6deg",
  "12": "12deg",
  "45": "45deg",
  "90": "90deg",
  "180": "180deg"
}